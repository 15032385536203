.card {
    border: 1px solid #eee;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
    padding: 10px;
    max-width: 400px;
    margin: 20px auto;
    border-radius: 4px;
}

.card button {
    background: #555;
}